<template>
  <div class="page page-about">
    <h1 class="page-about__title">
      О портале
    </h1>

    <p class="typo-accent-m">
      Версия клиента: <strong class="typo-monospace">{{ clientVersion }}</strong>
    </p>
  </div>
</template>

<script>
import packageJson from '../../package.json';

export default {
  name: 'About',

  computed: {
    clientVersion() {
      return packageJson.version;
    },
  },
};
</script>

<style lang="scss">
.page-about {
  &__title {
    margin-bottom: 20px;
  }
}
</style>
